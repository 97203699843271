@import "variables";
@import "mixins";

.next-steps {
    .content {
        padding-bottom: 0;
        h4 {
            font-weight: 500;
            span img {
                width: 25px;
                height: auto;
                margin: -5px 12px 0 0;
            }
        }
    }
    .collapsible {
        .collapsible-body .time {
            font-size: $small-text;
            padding-bottom: 10px;
        }
    }
    .back-to-map {
        margin: 20px 0 0 0;
        a {
            color: $call-to-action-colour;
            img {
                margin: 5px 10px 0 0;
                vertical-align: top;
            }
        }
    }
}

.center-loader {
    margin: 100px auto;
    display: block;
}